import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UnreadMyAlerts } from '@app/models/my-alerts.model';
import { AdminService } from '@app/services/admin.service';
import { CompanyStoreService } from '@app/services/company-store.service';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { MyAlertsService } from '@app/services/my-alerts.service';
import { FeedType } from '@app/shared/constants/feed-type.constants';
import { ENV } from '@environments/environment.provider';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();

  public isEndOfAlertsStream = false;

  isActivityFeedAdmin$ = this._adminService.isActivityFeedAnyAdmin$;
  isAlertManagementAdmin$ = this._adminService.isAlertManagementAdmin$;
  isAlertManagementView$ = this._adminService.isAlertManagementView$;
  alertManagementBaseUrl = this.env.zonarApps.alertManagementBaseUrl;

  constructor(
    public myAlertService: MyAlertsService,
    public httpClient: HttpClient,
    private _featureToggleService: FeatureToggleService,
    private _adminService: AdminService,
    @Inject(ENV) private env: any,
    private _companyService: CompanyStoreService
  ) {}

  isHideUnreadMyAlerts = true;
  unreadMyAlerts$: BehaviorSubject<UnreadMyAlerts> = new BehaviorSubject(null);
  isMyAlertsSelected = false;

  isMyAlertsTabFeatureToggleEnabled$ = this._featureToggleService.isFeatureEnabled('my-alerts').pipe(takeUntil(this._onDestroy$));
  isManageAlertsButtonDisplayed$ = combineLatest([
    this._featureToggleService.isFeatureEnabled('manage-alerts'),
    this.isAlertManagementAdmin$
  ]).pipe(
    map(
      ([isManageAlertsButtonFeatureToggleEnabled, isAlertManagementAdmin]) =>
        isManageAlertsButtonFeatureToggleEnabled && isAlertManagementAdmin
    ),
    takeUntil(this._onDestroy$)
  );
  isMyAlertsSettingsButtonDisplayed$ = combineLatest([
    this._featureToggleService.isFeatureEnabled('manage-alerts'),
    this.isAlertManagementView$
  ]).pipe(
    map(
      ([isManageAlertsButtonFeatureToggleEnabled, isAlertManagementView]) =>
        isManageAlertsButtonFeatureToggleEnabled && isAlertManagementView
    ),
    takeUntil(this._onDestroy$)
  );

  isClearingFilters = false;

  companyId: string = '';
  isCompanyLoaded = false;

  MY_ALERTS_FEED_TYPE_CONSTANT = FeedType.MyAlerts;
  ALL_ACTIVITY_FEED_TYPE_CONSTANT = FeedType.AllActivity;
  feedType: string = this.MY_ALERTS_FEED_TYPE_CONSTANT;

  /**
   * Waits until all tab-based feature toggle checks are finished before tab group
   * is loaded to ensure proper tab ordering
   *
   * Add any new tab-based feature toggles to the array in combineLatest(), and create
   * a new variable in order to show/hide the tab (e.g. isMyAlertsTabVisible)
   *
   * @returns Returns true at end of Observable chain to ensure that mat-tab-group is always shown
   */

  allTabsLoaded$: Observable<boolean> = combineLatest([this.isMyAlertsTabFeatureToggleEnabled$]).pipe(
    map(([isMyAlertsToggleEnabled]) => {
      this.isMyAlertsTabVisible = Boolean(isMyAlertsToggleEnabled);
      this.isMyAlertsSelected = Boolean(isMyAlertsToggleEnabled);
    }),
    map(() => {
      return true;
    }),
    takeUntil(this._onDestroy$)
  );
  isMyAlertsTabVisible = false;

  ngOnInit(): void {
    this._featureToggleService.initializeDevCycle();
    this._companyService
      .getCompanyFromSideNav()
      .pipe(
        filter((company) => !!company),
        switchMap((company) => {
          this.companyId = company.value;
          this.isCompanyLoaded = true;
          return this._getUnreadMyAlertsFromApi$(company, this.isMyAlertsSelected);
        })
      )
      .subscribe();
  }

  private _getUnreadMyAlertsFromApi$(selectedCompany: Company, reset = false): Observable<void> {
    return this.myAlertService.getUnreadMyAlerts$(selectedCompany, reset).pipe(
      map((unreadMyAlerts) => {
        this.isHideUnreadMyAlerts = unreadMyAlerts.newAlertCount > 0 ? false : true;
        unreadMyAlerts.newAlertCount = unreadMyAlerts.newAlertCount < 100 ? unreadMyAlerts.newAlertCount : '99+';
        this.unreadMyAlerts$.next(unreadMyAlerts);
      })
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }

  selectedTabValue = (event) => {
    this.isMyAlertsSelected = false;
    switch (event.tab.textLabel) {
      case 'My Alerts':
        this.feedType = this.MY_ALERTS_FEED_TYPE_CONSTANT;
        this.isMyAlertsSelected = true;
        // AAG-235: Get new unread myAlerts number when click to any other tab
        this._companyService
          .getCompanyFromSideNav()
          .pipe(take(1))
          .subscribe((val) => {
            this.readUnreadMyAlerts(val, true);
          });
        break;
      case 'All Alerts':
        this.feedType = this.ALL_ACTIVITY_FEED_TYPE_CONSTANT;
        // AAG-235: Get new unread myAlerts number when click to any other tab
        this._companyService
          .getCompanyFromSideNav()
          .pipe(take(1))
          .subscribe((val) => {
            this.readUnreadMyAlerts(val);
          });
        break;
      default:
        break;
    }
  };

  readUnreadMyAlerts(company: Company, reset = false) {
    this._getUnreadMyAlertsFromApi$(company, reset).subscribe();
  }

  redirectToAlertsManagement() {
    window.location.href = this.alertManagementBaseUrl;
  }

  redirectToMyAlerts() {
    window.location.href = this.alertManagementBaseUrl + '/myalerts';
  }

  redirectToAddSubscription() {
    window.location.href = this.alertManagementBaseUrl + '/add';
  }
}
