import { environment as env } from '../../../environments/environment';

export const SIDENAV_HEADER_CONFIG = {
  // Put sidenav header configs here if needed
};

export const SIDENAV_MENU_CONFIG = [
  {
    text: 'Activity Feed',
    icon: 'home',
    routerLink: '/',
    children: [],
    isExpanded: false
  },
  {
    text: 'Maps',
    icon: 'map',
    routerLink: env.zonarApps.gtcxMapsBaseUrl,
    children: [],
    isExpanded: false
  }
];
export const SIDENAV_FOOTER_CONFIG = {
  items: []
};
