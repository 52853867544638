import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { appName } from 'src/environments/shared';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { SIDENAV_FOOTER_CONFIG, SIDENAV_HEADER_CONFIG, SIDENAV_MENU_CONFIG } from './shared/config/sidenav.config';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';
import { Company } from './models/company.model';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DatadogService } from './services/datadog.service';
import { AuthService } from '@auth0/auth0-angular';
import { FaviconsService } from '@zonar-ui/core';
import { PermissionsService } from '@zonar-ui/auth';
import { AdminService } from './services/admin.service';
import { CompanyStoreService } from './services/company-store.service';
import { UserCompany } from './models/company.model';
import { DeviceDetectionService } from './services/device-detection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  loading = true;
  title: string = appName;
  translationsNotLoaded = true;

  sidenavHeader = SIDENAV_HEADER_CONFIG;
  sidenavMenu = SIDENAV_MENU_CONFIG;
  sidenavFooter = SIDENAV_FOOTER_CONFIG;
  sidenavParams = {
    expanded: true,
    mobileOpened: true,
    footerOffset: false
  };

  currentCompany$: Subject<Company> = new Subject<Company>();

  currentCompany: Company | null = null;

  TIME_BETWEEN_COMPANY_CHANGES = 1000;

  constructor(
    private router: Router,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private permissionsService: PermissionsService,
    private pendoService: PendoService,
    public translateService: TranslateService,
    private i18nService: I18nService,
    private favicon: FaviconsService,
    public device: DeviceDetectionService,
    private datadogService: DatadogService,
    private authService: AuthService,
    private _adminService: AdminService,
    private _companyService: CompanyStoreService,
    private _gtcxMobileHelperService: GTCxMobileHelperService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService.use(this.i18nService.getLanguageInUse()).subscribe(() => {
        this.translationsNotLoaded = false;
      });
    }
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.zuiAnalytics.addGtmToDom();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    // when running locally via `ng serve`, the `version.json` asset will not exist, so we disable version checking via
    // fetching this `version.json` static content
    if (!environment.devTools) {
      this.authService.isAuthenticated$.pipe(filter((isAuthenticated) => Boolean(isAuthenticated))).subscribe(() => {
        // Start DataDog session replay recording
        this.datadogService.startSessionReplayRecording();
      });
    }

    this.datadogService.newRumTiming('activity_feed_app_init');
  }

  onSideNavMobileMenuButtonToggled(event) {
    this.sidenavParams.mobileOpened = event;
  }

  debounce(func, wait, immediate = false) {
    let timeout;
    return function () {
      // eslint-disable-next-line
      let context = this;
      // eslint-disable-next-line
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  switchToSelectedCompany(incomingCompany: UserCompany): void {
    this.permissionsService
      .getCompanyMap()
      .pipe(
        take(1),
        map((companies) => {
          if (companies && companies[incomingCompany.value]) {
            // Setting Company context to use it on Zonar-Owner-Id for selecting right profile.
            this.permissionsService.setCurrentCompanyContext(companies[incomingCompany.value]);
          }
          this._companyService.setCompany(incomingCompany);
          this.pendoService.initialize();
          this._adminService.initialize(incomingCompany.value);
          this._gtcxMobileHelperService.setCurrentCompany(incomingCompany.value);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.datadogService.stopSessionReplayRecording();
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
