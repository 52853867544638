import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/models';
import { UnreadMyAlerts } from '@app/models/my-alerts.model';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyAlertsService {
  constructor(public httpClient: HttpClient) {}

  public getUnreadMyAlerts$(selectedCompany: Company, reset = false): Observable<UnreadMyAlerts> {
    if (selectedCompany === null) {
      return;
    }

    const qString = `context.entity_ids.company_id:${selectedCompany.value}`;
    const queryParams = `&reset=${reset}`;
    const uri = `${env.apiBase}${env.apiApigeePrefix}/v1/alerts/alert-count?q=${qString}${queryParams}`;

    return this.httpClient.get<UnreadMyAlerts>(uri);
  }
}
