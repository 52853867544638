import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { ECompanyLoginMode, PermissionsService } from '@zonar-ui/auth';
import { IDivision } from '@zonar-ui/auth/lib/models/company.model';

import { ACTIVITY_FEED_AUTH_PERMISSIONS, ALERT_MANAGEMENT_AUTH_PERMISSIONS, AlertManagetmentPermissions } from '@environments/shared';
import { environment } from '@environments/environment';

const activityFeedAdminPermPatternBuilder = (companyId: string) => `:${companyId}:::${ACTIVITY_FEED_AUTH_PERMISSIONS.MANAGE_EVENTS}`;
const alertManagementAdminPermissionString = `${AlertManagetmentPermissions.defaultPermPrefix}:${ALERT_MANAGEMENT_AUTH_PERMISSIONS.MANAGE_ALERTS}`;
const alertManagementViewPermissionString = `${AlertManagetmentPermissions.defaultPermPrefix}:${ALERT_MANAGEMENT_AUTH_PERMISSIONS.VIEW_ALERTS}`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  isActivityFeedAnyAdmin$ = new ReplaySubject<boolean>(1);
  isAlertManagementAdmin$ = new ReplaySubject<boolean>(1);
  isAlertManagementView$ = new ReplaySubject<boolean>(1);

  constructor(private _permissionsService: PermissionsService) {}

  initialize(companyId: string) {
    this._permissionsService
      .getIsPermissionsLoaded()
      .pipe(
        filter((isLoaded) => isLoaded),
        switchMap(() => this._permissionsService.getIsZonarUser()),
        switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfCompanyAdmin$(companyId)))
      )
      .subscribe((isActivityFeedAdmin) => {
        this.isActivityFeedAnyAdmin$.next(isActivityFeedAdmin);
      });
    this._permissionsService
      .getIsPermissionsLoaded()
      .pipe(
        filter((isLoaded) => isLoaded),
        switchMap(() => this._permissionsService.getIsZonarUser()),
        switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfAlertManagetmentAdmin$(companyId)))
      )
      .subscribe((isAlertManagementAdmin) => {
        this.isAlertManagementAdmin$.next(isAlertManagementAdmin);
      });
    this._permissionsService
      .getIsPermissionsLoaded()
      .pipe(
        filter((isLoaded) => isLoaded),
        switchMap(() => this._permissionsService.getIsZonarUser()),
        switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfAlertManagetmentView$(companyId)))
      )
      .subscribe((isAlertManagementView) => {
        this.isAlertManagementView$.next(isAlertManagementView);
      });
  }

  /**
   * If the user is a alert management admin, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the alert management admin permission
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   * @param companyId the currently selected company on the UI
   */
  private checkIfAlertManagetmentAdmin$(companyId): Observable<boolean> {
    return combineLatest([
      this._permissionsService.hasPermissionForApplication(
        alertManagementAdminPermissionString,
        environment.alertManagementAdminConfigs.applicationId,
        companyId
      )
    ]).pipe(map(([isAlertManagementAdminRole]) => isAlertManagementAdminRole));
  }

  /**
   * If the user is a alert management view, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the alert management view permission
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   * @param companyId the currently selected company on the UI
   */
  private checkIfAlertManagetmentView$(companyId): Observable<boolean> {
    return combineLatest([
      this._permissionsService.hasPermissionForApplication(
        alertManagementViewPermissionString,
        environment.alertManagementAdminConfigs.applicationId,
        companyId
      )
    ]).pipe(map(([isAlertManagementViewRole]) => isAlertManagementViewRole));
  }

  /**
   * If the user is a company admin, then they need to match these criteria in order to be granted admin permissions:
   * 1. `roles` array needs to contain object which has `id` property equal to the adminRole parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   * 3. `applicationId` matches current application's ID
   * 4. `isAllDivisions$` value needs to be `true`
   * @param companyId the currently selected company on the UI
   */
  private checkIfCompanyAdmin$(companyId): Observable<boolean> {
    return combineLatest([
      this._permissionsService.hasPermission(activityFeedAdminPermPatternBuilder(companyId)),
      this.isLegacyDivisionsAll(companyId)
    ]).pipe(map(([isAdminRole, isLegacyDivisionsAll]) => isAdminRole && isLegacyDivisionsAll));
  }

  /**
   * Select current profile and check all divisions are LEGACY type
   *
   * @param companyId the currently selected company on the UI
   */
  private isLegacyDivisionsAll(companyId: string): Observable<boolean> {
    return combineLatest([this.getLegacyCompanyDivisionsByCompanyId(companyId), this.getProfileDivisions(companyId)]).pipe(
      map(([legacyCompanyDivisions, profileDivisions]) => {
        if (Array.isArray(profileDivisions)) {
          const profileDivisionsSet = new Set(profileDivisions);
          const legacyCompanyDivisionsSet = new Set(legacyCompanyDivisions);
          return profileDivisions.length === 0 || this._isSubset(profileDivisionsSet, legacyCompanyDivisionsSet);
        } else {
          return false;
        }
      })
    );
  }

  private _isSubset(setA, setB) {
    for (let elem of setB) {
      if (!setA.has(elem)) {
        return false;
      }
    }
    return true;
  }

  private getProfileDivisions(companyId: string) {
    return combineLatest([
      this._permissionsService.getCompanyMap(),
      this._permissionsService.getUserProfiles(),
      this._permissionsService.getUserGroupPolicies()
    ]).pipe(
      filter(([companyMap, userProfiles, policies]) => !!(companyMap && userProfiles && policies)),
      take(1),
      map(([companyMap, userProfiles, userPolicies]) => {
        const selectedCompany = companyMap[companyId] || {};
        if (selectedCompany.loginMode === ECompanyLoginMode.GROUP_POLICY) {
          const selectedPolicy = userPolicies.find(
            (userPolicy) =>
              userPolicy.policy.grants.find((grant) => grant.application.id === environment.auth.applicationId) &&
              userPolicy.tenant.scope.companies.find((company) => company.id === companyId)
          );

          if (!selectedPolicy) {
            return selectedPolicy;
          }

          return selectedPolicy.tenant.scope.divisions.map((division) => division.id);
        } else {
          const selectedProfiles = userProfiles.filter(
            (userProfile) =>
              userProfile.applicationId === environment.auth.alertManagementAppId &&
              userProfile.companyId === companyId &&
              !!userProfile.roles.find((role) => role.id === environment.auth.alertManagementRoleId) &&
              userProfile.status === 'ACTIVE'
          );

          const allDivisionsFromUserProfilesSet = new Set();

          selectedProfiles.map((profile) => {
            profile.divisions.forEach((division) => allDivisionsFromUserProfilesSet.add(division));
          });

          const allDivisionsFromUserProfilesArray = Array.from(allDivisionsFromUserProfilesSet);
          return allDivisionsFromUserProfilesArray;
        }
      })
    );
  }

  /**
   * Get the LEGACY divisions list from selected company
   *
   * @param companyId the currently selected company on the UI
   */
  private getLegacyCompanyDivisionsByCompanyId(companyId: string): Observable<string[]> {
    return this._permissionsService.getCompanyDivisions().pipe(
      filter((divisionMap) => !!divisionMap),
      map((divisionMap) => {
        return this.convertToLegacyDivisions(companyId, divisionMap);
      })
    );
  }

  /**
   * Logic filter legacy division
   *
   * @param companyId the currently selected company on the UI
   * @param divisionMap The all division that built into divisionId:divisionObject before
   */
  private convertToLegacyDivisions(companyId, divisionMap): string[] {
    const companyDivisions = (Object.values(divisionMap)[0] || []) as IDivision[];
    return companyDivisions.reduce((acc: string[], division: IDivision) => {
      if (division.companyId === companyId && division.type === 'LEGACY' && division.status === 'ACTIVE') {
        acc.push(division.id);
      }
      return acc;
    }, []) as string[];
  }
}
