// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details
export const activityFeedPermissions = {
  defaultRedirectUrl: '/',
  defaultPermPrefix: 'gtcx_activityfeed',
  routes: [['/', 'read:assets']]
};
export const appName = 'activity-feed';
export const appVersion = '0.0.1';

export const ACTIVITY_FEED_AUTH_PERMISSIONS = {
  MANAGE_EVENTS: 'manage:events'
};

export const AlertManagetmentPermissions = {
  defaultRedirectUrl: '/',
  defaultPermPrefix: 'gtcx_am',
  routes: [['/', 'read:assets']]
};

export const ALERT_MANAGEMENT_AUTH_PERMISSIONS = {
  MANAGE_ALERTS: 'manage:alerts',
  VIEW_ALERTS: 'view:alerts'
};
