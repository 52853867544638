<div class="container">
  <div class="flex flex-row justify-between items-center u-margin__top--large">
    <h1 class="margin-bottom__none">Alerts</h1>
    <div class="alert-buttons-container">
      <button
        *ngIf="isManageAlertsButtonDisplayed$ | async"
        mat-stroked-button
        color="accent"
        type="button"
        (click)="redirectToAlertsManagement()"
      >
        <mat-icon>settings</mat-icon>
        <span class="hide-on-mobile u-margin__left--extra-tiny">Manage company alerts</span>
      </button>
      <button
        mat-stroked-button
        color="accent"
        type="button"
        (click)="redirectToMyAlerts()"
      >
        <mat-icon>tune</mat-icon>
        <span class="hide-on-mobile u-margin__left--extra-tiny">My alerts settings</span>
      </button>
    </div>
  </div>

  <span *ngIf="allTabsLoaded$ | withLoading | async as allTabsLoaded">
    <ng-template [ngIf]="allTabsLoaded.value">
      <mat-tab-group *ngIf="allTabsLoaded$ | async as allTabsLoaded" class="tab-group" (selectedTabChange)="selectedTabValue($event)">
        <mat-tab label="My Alerts" *ngIf="isMyAlertsTabVisible">
          <ng-template mat-tab-label>
            <span
              [matBadge]="(unreadMyAlerts$ | async)?.newAlertCount"
              [matBadgeHidden]="isHideUnreadMyAlerts"
              matBadgeOverlap="false"
              matBadgeColor="warn"
              matBadgeSize="medium"
              class="mat-tab-label-style"
              >My Alerts</span
            >
          </ng-template>
          <ng-template matTabContent>
            <span *ngIf="isCompanyLoaded">
              <zui-activity-feed [companyId]="companyId" [feedType]="MY_ALERTS_FEED_TYPE_CONSTANT"></zui-activity-feed>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab label="All Alerts">
          <ng-template mat-tab-label>
            <span class="mat-tab-label-style">All Activity</span>
          </ng-template>
          <ng-template matTabContent>
            <span *ngIf="isCompanyLoaded">
              <zui-activity-feed [companyId]="companyId" [feedType]="ALL_ACTIVITY_FEED_TYPE_CONSTANT"></zui-activity-feed>
            </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
    <ng-template [ngIf]="allTabsLoaded.error">
      <div>Error loading page. Please refresh to try again.</div>
    </ng-template>
    <ng-template [ngIf]="allTabsLoaded.loading">
      <div class="spinner-container" aria-labelledby="tab-loading-spinner"><zui-spinner diameter="150"></zui-spinner></div>
    </ng-template>
  </span>
</div>
